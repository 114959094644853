import {
    FETCH_CLIENTS_SUCCESS,
    FETCH_CLIENTS_FAILURE,
    FETCH_ASSISTANTS_SUCCESS,
    FETCH_ASSISTANTS_FAILURE,
    FETCH_ALL_ASSISTANTS_SUCCESS,
    FETCH_ALL_ASSISTANTS_FAILURE,
    UPDATE_ASSISTANT_SUCCESS,
    UPDATE_ASSISTANT_FAILURE
  } from '../actions/assistantActions';
  
  const initialState = {
    clients: [],
    assistants: [],
    allAssistants: [],
    error: null,
  };
  
  const assistantReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_CLIENTS_SUCCESS:
        return {
          ...state,
          clients: action.payload,
          error: null,
        };
      case FETCH_CLIENTS_FAILURE:
        return {
          ...state,
          error: action.error,
        };
      case FETCH_ASSISTANTS_SUCCESS:
        return {
          ...state,
          assistants: action.payload,
          error: null,
        };
      case FETCH_ASSISTANTS_FAILURE:
        return {
          ...state,
          error: action.error,
        };
      case FETCH_ALL_ASSISTANTS_SUCCESS:
        return {
          ...state,
          allAssistants: Array.isArray(action.payload) ? action.payload : [],
          error: null,
        };
      case FETCH_ALL_ASSISTANTS_FAILURE:
        return {
          ...state,
          error: action.error,
        };
      case UPDATE_ASSISTANT_SUCCESS:
        return {
          ...state,
          error: null,
        };
      case UPDATE_ASSISTANT_FAILURE:
        return {
          ...state,
          error: action.error,
        };
      default:
        return state;
    }
  };
  
  export default assistantReducer;
  