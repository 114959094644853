import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, Typography, InputAdornment, IconButton, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateClient } from '../actions/clientActions';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const UpdateClientModal = ({ open, onClose, initialData }) => {
  const [formData, setFormData] = useState({
    _id: '',
    name: '',
    address: '',
    company: '',
    contactNumber: '',
    email: '',
    password: '',
    callerNumbers: [], // Ensure callerNumbers defaults to an empty array
  });
  const [showPassword, setShowPassword] = useState(false);
  const [newCallerNumber, setNewCallerNumber] = useState('');
  const [isEditable, setIsEditable] = useState(false); // Disable form fields initially

  const dispatch = useDispatch();

  // Populate formData when initialData changes and is valid
  useEffect(() => {
    if (initialData) {
      setFormData({
        ...initialData,
        callerNumbers: initialData.callerNumbers || [], // Ensure callerNumbers is always an array
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddCallerNumber = () => {
    if (newCallerNumber) {
      setFormData((prev) => ({
        ...prev,
        callerNumbers: [...prev.callerNumbers, newCallerNumber],
      }));
      setNewCallerNumber('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateClient(formData));
    onClose();
  };

  const toggleEditable = () => {
    setIsEditable((prev) => !prev);
  };

  return (
    <Modal
  open={open}
  onClose={onClose}
  BackdropProps={{
    style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }, // Set backdrop opacity
  }}
>
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      p: 4,
      width: '60%',
      maxWidth: 800,
      mx: 'auto',
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
    }}
  >
    <Box sx={{ width: '100%' }}>
      <Typography variant="h6">Update Client</Typography>

      <Button
        onClick={toggleEditable}
        variant="outlined"
        startIcon={isEditable ? <LockOpenIcon /> : <LockIcon />}
        sx={{ mt: 2, mb: 2 }}
      >
        {isEditable ? 'Lock Fields' : 'Unlock Fields'}
      </Button>

      {formData._id && (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                name="name"
                fullWidth
                onChange={handleChange}
                value={formData.name}
                required
                disabled={!isEditable}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Address"
                name="address"
                fullWidth
                onChange={handleChange}
                value={formData.address}
                required
                disabled={!isEditable}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Company"
                name="company"
                fullWidth
                onChange={handleChange}
                value={formData.company}
                required
                disabled={!isEditable}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Contact Number"
                name="contactNumber"
                fullWidth
                onChange={handleChange}
                value={formData.contactNumber}
                disabled={!isEditable}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                name="email"
                fullWidth
                onChange={handleChange}
                value={formData.email}
                required
                disabled={!isEditable}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                required
                onChange={handleChange}
                value={formData.password}
                disabled={!isEditable}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Box mt={3}>
            <Typography variant="subtitle1">Caller Numbers</Typography>
            <Box display="flex" mt={1}>
              <TextField
                label="Add Caller Number"
                value={newCallerNumber}
                onChange={(e) => setNewCallerNumber(e.target.value)}
                sx={{ flexGrow: 1, mr: 1 }}
                disabled={!isEditable}
              />
              <Button
                variant="contained"
                onClick={handleAddCallerNumber}
                disabled={!isEditable}
              >
                Add
              </Button>
            </Box>
            <Box mt={1}>
              {formData.callerNumbers?.map((number, index) => (
                <Typography key={index}>{number}</Typography>
              ))}
            </Box>
          </Box>

          <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }} disabled={!isEditable}>
            Update Client
          </Button>
        </Box>
      )}
    </Box>
  </Box>
</Modal>

  );
};

export default UpdateClientModal;
