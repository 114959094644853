// src/pages/HomePage.js
import React from 'react';
import { Container, Typography } from '@mui/material';

const HomePage = () => {
  return (
    <Container>
      <Typography variant="h4">Home Page</Typography>
    </Container>
  );
};

export default HomePage;
