import React, { useState } from 'react';
import { Box, Button, Modal, TextField, Typography, InputAdornment, IconButton, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { createClient } from '../actions/clientActions';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const CreateClientModal = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    company: '',
    contactNumber: '',
    email: '',
    password: '',
    callerNumbers: [],
  });
  const [showPassword, setShowPassword] = useState(false);
  const [newCallerNumber, setNewCallerNumber] = useState('');

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddCallerNumber = () => {
    if (newCallerNumber) {
      setFormData((prev) => ({
        ...prev,
        callerNumbers: [...prev.callerNumbers, newCallerNumber],
      }));
      setNewCallerNumber('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const clientData = { ...formData };
    dispatch(createClient(clientData));
    onClose();
  };

  return (
    <Modal
  open={open}
  onClose={onClose}
  BackdropProps={{
    style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }, // Set backdrop opacity
  }}
>
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      p: 4,
      width: '60%',
      mt:'2%',
      maxWidth: 800,
      mx: 'auto',
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
    }}
  >
    <Box sx={{ width: '100%' }}>
      <Typography variant="h6">Add Client</Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Name"
              name="name"
              fullWidth
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Address"
              name="address"
              fullWidth
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Company"
              name="company"
              fullWidth
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Contact Number"
              name="contactNumber"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              name="email"
              fullWidth
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              required
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Box mt={3}>
          <Typography variant="subtitle1">Caller Numbers</Typography>
          <Box display="flex" mt={1}>
            <TextField
              label="Add Caller Number"
              value={newCallerNumber}
              onChange={(e) => setNewCallerNumber(e.target.value)}
              sx={{ flexGrow: 1, mr: 1 }}
            />
            <Button variant="contained" onClick={handleAddCallerNumber}>
              Add
            </Button>
          </Box>
          <Box mt={1}>
            {formData.callerNumbers.map((number, index) => (
              <Typography key={index}>{number}</Typography>
            ))}
          </Box>
        </Box>

        <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
          Add Client
        </Button>
      </Box>
    </Box>
  </Box>
</Modal>

  );
};

export default CreateClientModal;
