import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClients } from '../actions/clientActions';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  InputAdornment,
  Toolbar,
  useTheme,
  useMediaQuery
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import CreateClientModal from '../modals/CreateClientModal';
import UpdateClientModal from '../modals/UpdateClientModal';

const Onboarding = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const dispatch = useDispatch();
  const clients = useSelector((state) => state.client?.clients ?? []);

  useEffect(() => {
    dispatch(fetchClients());
  }, [dispatch]);

  const handleEdit = (client) => {
    setSelectedClient(client);
    setOpenUpdateModal(true);
  };

  const filteredClients = clients.filter((client) =>
    (client._id && client._id.includes(searchQuery)) ||
    (client.name && client.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (client.company && client.company.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (client.email && client.email.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      {/* <Toolbar /> */}
      <Container maxWidth="lg">
        <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
          <Typography variant="h4" gutterBottom>
            Client Onboarding
          </Typography>
          <Box display="flex" alignItems="center" width="100%" justifyContent="space-between">
            <TextField
              variant="outlined"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
              }}
              sx={{ flexGrow: 1, mr: 2 }}
            />
            <Button variant="contained" color="primary" onClick={() => setOpenCreateModal(true)}>
              Add Client
            </Button>
          </Box>
        </Box>

        {/* Table displaying clients */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredClients.map((client) => (
                <TableRow key={client._id}>
                  <TableCell>{client._id}</TableCell>
                  <TableCell>{client.company}</TableCell>
                  <TableCell>{client.email}</TableCell>
                  <TableCell>{client.address}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEdit(client)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Create and Update Modals */}
        <CreateClientModal open={openCreateModal} onClose={() => setOpenCreateModal(false)} />
        <UpdateClientModal
          open={openUpdateModal}
          onClose={() => setOpenUpdateModal(false)}
          initialData={selectedClient}
        />
      </Container>
    </Box>
  );
};

export default Onboarding;
