import {
    getClients,
    fetchAssistantsByClientId as fetchAssistantsByClientIdAPI,
    fetchAllAssistants as fetchAllAssistantsAPI,
    updateAssistant as updateAssistantAPI
  } from '../services/interlogueAPI';
  
  export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';
  export const FETCH_CLIENTS_FAILURE = 'FETCH_CLIENTS_FAILURE';
  export const FETCH_ASSISTANTS_SUCCESS = 'FETCH_ASSISTANTS_SUCCESS';
  export const FETCH_ASSISTANTS_FAILURE = 'FETCH_ASSISTANTS_FAILURE';
  export const FETCH_ALL_ASSISTANTS_SUCCESS = 'FETCH_ALL_ASSISTANTS_SUCCESS';
  export const FETCH_ALL_ASSISTANTS_FAILURE = 'FETCH_ALL_ASSISTANTS_FAILURE';
  export const UPDATE_ASSISTANT_SUCCESS = 'UPDATE_ASSISTANT_SUCCESS';
  export const UPDATE_ASSISTANT_FAILURE = 'UPDATE_ASSISTANT_FAILURE';
  
  export const fetchClients = () => async (dispatch) => {
    try {
      const response = await getClients();
      dispatch({ type: FETCH_CLIENTS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: FETCH_CLIENTS_FAILURE, error });
    }
  };
  
  export const fetchAssistantsByClientId = (clientId) => async (dispatch) => {
    try {
      const response = await fetchAssistantsByClientIdAPI(clientId);
      dispatch({ type: FETCH_ASSISTANTS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: FETCH_ASSISTANTS_FAILURE, error });
    }
  };
  
  export const fetchAllAssistants = () => async (dispatch) => {
    try {
      const response = await fetchAllAssistantsAPI();
      dispatch({ type: FETCH_ALL_ASSISTANTS_SUCCESS, payload: response });
    } catch (error) {
      dispatch({ type: FETCH_ALL_ASSISTANTS_FAILURE, error });
    }
  };
  
  export const updateAssistant = (data) => async (dispatch) => {
    try {
      const newDoc = {
        assistantId : data._id,  // Ensure the _id remains the same
        newDocs:{
        agent_name: data.agent_name,
        payload: data.payload,
        clientId: data.clientId,
        appId: data.appId
      }
      };
      await updateAssistantAPI(newDoc);
      dispatch({ type: UPDATE_ASSISTANT_SUCCESS });
      dispatch(fetchAssistantsByClientId(data.clientId)); // Refresh the assistants list
    } catch (error) {
      dispatch({ type: UPDATE_ASSISTANT_FAILURE, error });
    }
  };
  