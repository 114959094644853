// src/actions/clientActions.js
import { createClient as createClientService, getClients as getClientsService } from '../services/interlogueAPI';


export const fetchClients = () => async (dispatch) => {
  try {
    const response = await getClientsService();
    dispatch({ type: 'FETCH_CLIENTS_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_CLIENTS_FAILURE', payload: error });
  }
};

export const createClient = (clientData) => async (dispatch) => {
  try {
    const response = await createClientService(clientData);
    dispatch({ type: 'CREATE_CLIENT_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'CREATE_CLIENT_FAILURE', payload: error });
  }
};

export const updateClient = (clientData) => async (dispatch) => {
  try {
    const response = await createClientService(clientData); // Same API endpoint for update
    dispatch({ type: 'UPDATE_CLIENT_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'UPDATE_CLIENT_FAILURE', payload: error });
  }
};
