// src/actions/balanceActions.js
import { fetchBalanceHistory as fetchBalanceHistoryAPI, updateBalance as updateBalanceAPI } from '../services/interlogueAPI';

export const fetchBalanceHistory = (clientId) => async (dispatch) => {
  try {
    const data = await fetchBalanceHistoryAPI(clientId);
    dispatch({ type: 'FETCH_BALANCE_HISTORY_SUCCESS', payload: data });
  } catch (error) {
    dispatch({ type: 'FETCH_BALANCE_HISTORY_FAILURE', payload: error });
  }
};

export const updateBalance = (balanceData) => async (dispatch) => {
  try {
    const data = await updateBalanceAPI(balanceData);
    dispatch({ type: 'UPDATE_BALANCE_SUCCESS', payload: data });
  } catch (error) {
    dispatch({ type: 'UPDATE_BALANCE_FAILURE', payload: error });
  }
};
