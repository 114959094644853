export const updateNestedState = (state, path, value) => {
    const keys = path.split('.');
    const lastKey = keys.pop();
    const deepClone = JSON.parse(JSON.stringify(state));
  
    let current = deepClone;
    keys.forEach((key) => {
      if (!current[key]) {
        current[key] = {};
      }
      current = current[key];
    });
  
    current[lastKey] = value;
    return deepClone;
  };
  