import axios from 'axios';

const API_URL = 'https://app.glimpass.com/interlogue'; // Replace with your actual API URL
const AGENT_URL = 'https://ivrsp.glimpass.com/agent'; // Replace with your actual Agent URL

export const createClient = (clientData) => {
  return axios.post(`${API_URL}/create-client`, clientData);
};

export const getClients = () => {
  return axios.get(`${API_URL}/get-all-clients`);
};

export const createAgent = (agentData) => {
  return axios.post(`${API_URL}/create-assistant`, agentData);
};

export const updateAgent = (agentData) => {
  return axios.post(`${AGENT_URL}`, agentData);
};

export const fetchAssistantsByClientId = (clientId) => {
  return axios.post(`${API_URL}/get-assistant-by-client`,{_id: clientId});
};

export const fetchAllAssistants = async () => {
    try {
      const response = await axios.get(`${API_URL}/get-all-assistants`);
      console.log('Response:', response.data); // Debugging: Check the response
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error('Error fetching all assistants:', error);
      throw error;
    }
  };

export const updateAssistant = (data) => {
  return axios.post(`${API_URL}/update-assistant`, data);
};

export const submitAgent = async (agentConfig, agentPrompts) => {
  try {
    // Create agent
    const createResponse = await createAgent({
      agent_name: agentConfig.agent_name,
      payload: {
        agent_config: agentConfig,
        agent_prompts: agentPrompts,
      },
    });

    const agentId = createResponse.data._id;

    // Prepare update payload
    const updatePayload = {
      agent_config: { ...agentConfig, agent_id: agentId },
      agent_prompts: agentPrompts,
      agent_id: agentId,
    };

    // Update agent
    const updateResponse = await updateAgent(updatePayload);

    // If the update is successful (status 200), return the response
    if (updateResponse.status === 200) {
      return updateResponse;
    }

    throw new Error('Failed to update the agent');
  } catch (error) {
    // Check for Network Error specifically
    if (error.message === 'Network Error') {
      // Handle Network Error gracefully
      console.warn('Network error occurred. Please check your connection.');
      return; // You can choose to suppress the error or provide a fallback action
    }

    // If the error has a response and status is 200, handle it accordingly
    if (error.response && error.response.status === 200) {
      return;
    }

    // Log other errors if necessary or rethrow
    console.error('Error:', error.message || error);
    throw error;
  }
};



export const fetchBalanceHistory = async (clientId) => {
  try {
    const response = await axios.get(`${API_URL}/get-balance-history/${clientId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching balance history:', error);
    throw error;
  }
};

export const updateBalance = async (balanceData) => {
  try {
    const response = await axios.post(`${API_URL}/update-client`, balanceData);
    return response.data;
  } catch (error) {
    console.error('Error updating balance:', error);
    throw error;
  }
};