// src/reducers/index.js
import { combineReducers } from 'redux';
import userReducer from './userReducer';
import clientReducer from './clientReducers';
import balanceReducer from './balanceReducers';
import agentReducer from './agentReducers';
import assistantReducer from './assistantReducer';

const rootReducer = combineReducers({
  user: userReducer,
  client: clientReducer,
  balance: balanceReducer,
  agent: agentReducer,
  assistant: assistantReducer
});

export default rootReducer;
