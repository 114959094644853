// src/reducers/balanceReducer.js
const initialState = {
    history: [],
    loading: false,
    error: null,
  };
  
  const balanceReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_BALANCE_HISTORY_SUCCESS':
        return {
          ...state,
          history: action.payload,
        };
      case 'FETCH_BALANCE_HISTORY_FAILURE':
        return {
          ...state,
          error: action.payload,
        };
      case 'UPDATE_BALANCE_SUCCESS':
        return {
          ...state,
          // Optionally update the state if needed
        };
      case 'UPDATE_BALANCE_FAILURE':
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default balanceReducer;
  