import React, { useState, useEffect } from 'react';
import {
  Container,
  Button,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAssistants, updateAssistant } from '../actions/assistantActions';
import EditIcon from '@mui/icons-material/Edit';

const ListAssistants = () => {
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [newAppId, setNewAppId] = useState('');
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // New state for search input

  const dispatch = useDispatch();
  const allAssistants = useSelector((state) => state.assistant.allAssistants || []);

  // Fetch all assistants on component mount
  useEffect(() => {
    dispatch(fetchAllAssistants());
  }, [dispatch]);

  const handleEditClick = (assistant) => {
    setSelectedAssistant(assistant);
    setNewAppId(assistant.appId || ''); // Prefill with existing appId if available
    setOpenEditDialog(true);
  };

  const handleDialogClose = () => {
    setOpenEditDialog(false);
    setSelectedAssistant(null);
    setNewAppId('');
  };

  const handleUpdateAppId = async () => {
    if (selectedAssistant) {
      await dispatch(updateAssistant({ ...selectedAssistant, appId: newAppId }));
      setOpenEditDialog(false);
      dispatch(fetchAllAssistants()); // Refresh the list after update
    }
  };

  // Filter assistants based on search input
  const filteredAssistants = allAssistants.filter(
    (assistant) =>
      assistant.agent_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      assistant._id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container maxWidth="lg">
      <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
        <Typography variant="h4" gutterBottom>
          List of Assistants
        </Typography>
      </Box>

      {/* Search Bar */}
      <Box display="flex" justifyContent="center" mb={4}>
        <TextField
          label="Search by Assistant Name or ID"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Assistant ID</TableCell>
              <TableCell>Campaign Name</TableCell>
              <TableCell>Client ID</TableCell>
              <TableCell>App ID</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAssistants.length > 0 ? (
              filteredAssistants.map((assistant) => (
                <TableRow key={assistant._id}>
                  <TableCell>{assistant._id}</TableCell>
                  <TableCell>{assistant.agent_name}</TableCell>
                  <TableCell>{assistant.clientId || 'N/A'}</TableCell>
                  <TableCell>{assistant.appId || 'N/A'}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEditClick(assistant)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No assistants found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Dialog */}
      <Dialog open={openEditDialog} onClose={handleDialogClose}>
        <DialogTitle>Edit App ID</DialogTitle>
        <DialogContent>
          <TextField
            label="App ID"
            fullWidth
            value={newAppId}
            onChange={(e) => setNewAppId(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpdateAppId} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ListAssistants;
