import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Collapse,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { updateAgentConfig, submitAgentConfig } from '../actions/agentActions';
import { updateNestedState } from '../utils/updateNestedState';
import { useNavigate } from 'react-router-dom';


const Assistant = () => {
  const dispatch = useDispatch();
  const agentConfig = useSelector((state) => state.agent.agentConfig);
  const agentPrompts = useSelector((state) => state.agent.agentPrompts);
  const [taskConfigOpen, setTaskConfigOpen] = useState(false);

  const navigate = useNavigate();

  const handleTaskConfigToggle = () => {
    setTaskConfigOpen(!taskConfigOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newAgentConfig = updateNestedState(agentConfig, name, value);
    dispatch(updateAgentConfig({ agentConfig: newAgentConfig }));
  };

  const handlePromptChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateAgentConfig({ agentPrompts: { task_1: { [name]: value } } }));
  };

  const handleSubmit = async () => {
    try {
      await dispatch(submitAgentConfig({ agentConfig, agentPrompts })).unwrap();
      navigate('/list-assistants')
      alert('Agent created and updated successfully!');
    } catch (error) {
      alert('Error creating or updating agent: ' + error.message);
      navigate('/list-assistants')
    }
  };

  return (
    <Container maxWidth="md">
      <Box mb={4} mt={2}>
        <Typography variant="h4" gutterBottom>
          Assistant Configuration
        </Typography>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Agent Name"
            name="agent_name"
            value={agentConfig.agent_name}
            onChange={handleChange}
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="First Message"
            name="agent_welcome_message"
            value={agentConfig.agent_welcome_message}
            onChange={handleChange}
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="System Prompt"
            name="system_prompt"
            value={agentPrompts.task_1.system_prompt}
            onChange={handlePromptChange}
            variant="outlined"
            multiline
            rows={4}
          />
        </FormControl>

        <Box mt={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Task Configuration</Typography>
            <IconButton onClick={handleTaskConfigToggle}>
              {taskConfigOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Collapse in={taskConfigOpen}>
            <Box mt={2} pl={2}>
              <Typography variant="subtitle1">Task Type</Typography>
              <FormControl fullWidth margin="normal">
                <Select
                  name="tasks.0.task_type"
                  value={agentConfig.tasks[0].task_type}
                  onChange={handleChange}
                >
                  <MenuItem value="conversation">Conversation</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>

              <Typography variant="subtitle1">Toolchain Execution</Typography>
              <FormControl fullWidth margin="normal">
                <Select
                  name="tasks.0.toolchain.execution"
                  value={agentConfig.tasks[0].toolchain.execution}
                  onChange={handleChange}
                >
                  <MenuItem value="parallel">Parallel</MenuItem>
                  <MenuItem value="sequential">Sequential</MenuItem>
                </Select>
              </FormControl>

              <Typography variant="subtitle1">Input Configuration</Typography>
              <TextField
                label="Input Format"
                name="tasks.0.tools_config.input.format"
                value={agentConfig.tasks[0].tools_config.input.format}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Input Provider"
                name="tasks.0.tools_config.input.provider"
                value={agentConfig.tasks[0].tools_config.input.provider}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />

              <Typography variant="subtitle1">LLM Agent Configuration</Typography>
              <TextField
                label="Agent Flow Type"
                name="tasks.0.tools_config.llm_agent.agent_flow_type"
                value={agentConfig.tasks[0].tools_config.llm_agent.agent_flow_type}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Family"
                name="tasks.0.tools_config.llm_agent.family"
                value={agentConfig.tasks[0].tools_config.llm_agent.family}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Model"
                name="tasks.0.tools_config.llm_agent.model"
                value={agentConfig.tasks[0].tools_config.llm_agent.model}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Max Tokens"
                name="tasks.0.tools_config.llm_agent.max_tokens"
                value={agentConfig.tasks[0].tools_config.llm_agent.max_tokens}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Temperature"
                name="tasks.0.tools_config.llm_agent.temperature"
                value={agentConfig.tasks[0].tools_config.llm_agent.temperature}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Top K"
                name="tasks.0.tools_config.llm_agent.top_k"
                value={agentConfig.tasks[0].tools_config.llm_agent.top_k}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Top P"
                name="tasks.0.tools_config.llm_agent.top_p"
                value={agentConfig.tasks[0].tools_config.llm_agent.top_p}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Min P"
                name="tasks.0.tools_config.llm_agent.min_p"
                value={agentConfig.tasks[0].tools_config.llm_agent.min_p}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Frequency Penalty"
                name="tasks.0.tools_config.llm_agent.frequency_penalty"
                value={agentConfig.tasks[0].tools_config.llm_agent.frequency_penalty}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Presence Penalty"
                name="tasks.0.tools_config.llm_agent.presence_penalty"
                value={agentConfig.tasks[0].tools_config.llm_agent.presence_penalty}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Provider"
                name="tasks.0.tools_config.llm_agent.provider"
                value={agentConfig.tasks[0].tools_config.llm_agent.provider}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />

              <Typography variant="subtitle1">Output Configuration</Typography>
              <TextField
                label="Output Format"
                name="tasks.0.tools_config.output.format"
                value={agentConfig.tasks[0].tools_config.output.format}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Output Provider"
                name="tasks.0.tools_config.output.provider"
                value={agentConfig.tasks[0].tools_config.output.provider}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />

              <Typography variant="subtitle1">Synthesizer Configuration</Typography>
              <TextField
                label="Audio Format"
                name="tasks.0.tools_config.synthesizer.audio_format"
                value={agentConfig.tasks[0].tools_config.synthesizer.audio_format}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Provider"
                name="tasks.0.tools_config.synthesizer.provider"
                value={agentConfig.tasks[0].tools_config.synthesizer.provider}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Voice"
                name="tasks.0.tools_config.synthesizer.provider_config.voice"
                value={agentConfig.tasks[0].tools_config.synthesizer.provider_config.voice}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Model"
                name="tasks.0.tools_config.synthesizer.provider_config.model"
                value={agentConfig.tasks[0].tools_config.synthesizer.provider_config.model}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Voice ID"
                name="tasks.0.tools_config.synthesizer.provider_config.voice_id"
                value={agentConfig.tasks[0].tools_config.synthesizer.provider_config.voice_id}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Buffer Size"
                name="tasks.0.tools_config.synthesizer.buffer_size"
                value={agentConfig.tasks[0].tools_config.synthesizer.buffer_size}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />

              <Typography variant="subtitle1">Transcriber Configuration</Typography>
              <TextField
                label="Encoding"
                name="tasks.0.tools_config.transcriber.encoding"
                value={agentConfig.tasks[0].tools_config.transcriber.encoding}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Language"
                name="tasks.0.tools_config.transcriber.language"
                value={agentConfig.tasks[0].tools_config.transcriber.language}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Model"
                name="tasks.0.tools_config.transcriber.model"
                value={agentConfig.tasks[0].tools_config.transcriber.model}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Sampling Rate"
                name="tasks.0.tools_config.transcriber.sampling_rate"
                value={agentConfig.tasks[0].tools_config.transcriber.sampling_rate}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Endpointing"
                name="tasks.0.tools_config.transcriber.endpointing"
                value={agentConfig.tasks[0].tools_config.transcriber.endpointing}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Task"
                name="tasks.0.tools_config.transcriber.task"
                value={agentConfig.tasks[0].tools_config.transcriber.task}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />

              <Typography variant="subtitle1">Task Configuration</Typography>
              <TextField
                label="Optimize Latency"
                name="tasks.0.task_config.optimize_latency"
                value={agentConfig.tasks[0].task_config.optimize_latency}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Hangup After Silence"
                name="tasks.0.task_config.hangup_after_silence"
                value={agentConfig.tasks[0].task_config.hangup_after_silence}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Incremental Delay"
                name="tasks.0.task_config.incremental_delay"
                value={agentConfig.tasks[0].task_config.incremental_delay}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Number of Words for Interruption"
                name="tasks.0.task_config.number_of_words_for_interruption"
                value={agentConfig.tasks[0].task_config.number_of_words_for_interruption}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Interruption Backoff Period"
                name="tasks.0.task_config.interruption_backoff_period"
                value={agentConfig.tasks[0].task_config.interruption_backoff_period}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Hangup After LLM Call"
                name="tasks.0.task_config.hangup_after_LLMCall"
                value={agentConfig.tasks[0].task_config.hangup_after_LLMCall}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Backchanneling"
                name="tasks.0.task_config.backchanneling"
                value={agentConfig.tasks[0].task_config.backchanneling}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Backchanneling Message Gap"
                name="tasks.0.task_config.backchanneling_message_gap"
                value={agentConfig.tasks[0].task_config.backchanneling_message_gap}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Backchanneling Start Delay"
                name="tasks.0.task_config.backchanneling_start_delay"
                value={agentConfig.tasks[0].task_config.backchanneling_start_delay}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Ambient Noise"
                name="tasks.0.task_config.ambient_noise"
                value={agentConfig.tasks[0].task_config.ambient_noise}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                label="Max Duration"
                name="tasks.0.task_config.max_duration"
                value={agentConfig.tasks[0].task_config.max_duration}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
              />
            </Box>
          </Collapse>
        </Box>

        <Box mt={4} display="flex" justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Assistant;
