import React, { useState, useEffect } from 'react';
import {
  Container,
  Button,
  Typography,
  Box,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClients, fetchAssistantsByClientId, fetchAllAssistants, updateAssistant } from '../actions/assistantActions';

const AssignAssistants = () => {
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedAssistant, setSelectedAssistant] = useState('');
  const [selectedAssistantPayload, setSelectedAssistantPayload] = useState(null);
  const [selectedAssistantName, setSelectedAssistantName] = useState('');

  const dispatch = useDispatch();
  const clients = useSelector((state) => state.assistant.clients || []);
  const assistants = useSelector((state) => state.assistant.assistants || []);
  const allAssistants = useSelector((state) => state.assistant.allAssistants || []);

  // Fetch clients once on component mount
  useEffect(() => {
    dispatch(fetchClients());
  }, [dispatch]);

  // Fetch assistants when a client is selected
  useEffect(() => {
    if (selectedClient) {
      const fetchData = async () => {
        await dispatch(fetchAllAssistants());
        await dispatch(fetchAssistantsByClientId(selectedClient));
      };
      fetchData();
    }
  }, [selectedClient, dispatch]);

  const handleClientChange = (event) => {
    setSelectedClient(event.target.value);
    setSelectedAssistant(''); // Reset the assistant selection when client changes
  };

  const handleAssistantChange = (event) => {
    const assistantId = event.target.value;
    setSelectedAssistant(assistantId);
    const assistantPayload = allAssistants.find(assistant => assistant._id === assistantId);
    setSelectedAssistantPayload(assistantPayload.payload);
    setSelectedAssistantName(assistantPayload.agent_name);
  };

  const handleAssign = async () => {
    if (selectedClient && selectedAssistant) {
      await dispatch(updateAssistant({ clientId: selectedClient, _id: selectedAssistant, payload: selectedAssistantPayload, agent_name: selectedAssistantName }));
      // Refresh the assigned assistants list after assignment
      dispatch(fetchAssistantsByClientId(selectedClient));
    }
  };

  return (
    <Container maxWidth="lg">
      <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
        <Typography variant="h4" gutterBottom>
          Assign Assistants
        </Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel>Client Email</InputLabel>
          <Select
            value={selectedClient}
            onChange={handleClientChange}
            label="Client Email"
          >
            {clients.map((client) => (
              <MenuItem key={client._id} value={client._id}>
                {client.email}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>Assistant</InputLabel>
          <Select
            value={selectedAssistant}
            onChange={handleAssistantChange}
            label="Assistant"
            disabled={!selectedClient}
          >
            {allAssistants.map((assistant) => assistant.agent_name && (
              <MenuItem key={assistant._id} value={assistant._id}>
                {assistant.agent_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={handleAssign} disabled={!selectedClient || !selectedAssistant}>
          Assign Assistant
        </Button>
      </Box>
      <Typography variant="h6" gutterBottom>
        Assigned Assistants
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Assistant Name</TableCell>
              <TableCell>Assistant ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assistants.length > 0 ? assistants.map((assistant) => (
              <TableRow key={assistant._id}>
                <TableCell>{assistant.agent_name}</TableCell>
                <TableCell>{assistant._id}</TableCell>
              </TableRow>
            )) : (
              <TableRow>
                <TableCell colSpan={2} align="center">No assistants assigned yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AssignAssistants;
