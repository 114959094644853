import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { submitAgent, createAgent, updateAgent } from '../services/interlogueAPI';

// Define the async thunk for submitting agent configuration
export const submitAgentConfig = createAsyncThunk(
  'agent/submitAgentConfig',
  async ({ agentConfig, agentPrompts }, { rejectWithValue }) => {
    try {
      const createResponse = await createAgent({
        agent_name: agentConfig.agent_name,
        payload: {
          agent_config: agentConfig,
          agent_prompts: agentPrompts,
        },
      });

      const agentId = createResponse.data._id;

      const updatePayload = {
        agent_config: { ...agentConfig, agent_id: agentId },
        agent_prompts: agentPrompts,
        agent_id: agentId,
      };

      const updateResponse = await updateAgent(updatePayload);

      return updateResponse.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create the slice
const agentSlice = createSlice({
  name: 'agent',
  initialState: {
    agentConfig: {
      agent_name: '',
      agent_type: 'other',
      agent_welcome_message: '',
      tasks: [
        {
          task_type: 'conversation',
          toolchain: {
            execution: 'parallel',
            pipelines: [['transcriber', 'llm', 'synthesizer']],
          },
          tools_config: {
            input: {
              format: 'pcm',
              provider: 'exotel',
            },
            llm_agent: {
              agent_flow_type: 'streaming',
              family: 'openai',
              request_json: true,
              model: 'gpt-4o',
              use_fallback: true,
              max_tokens: 250,
              temperature: 0.5,
              stop: null,
              top_k: 0,
              top_p: 0.9,
              min_p: 0.1,
              frequency_penalty: 0.0,
              presence_penalty: 0.0,
              provider: 'openai',
              base_url: null,
              routes: null,
              extraction_details: null,
              summarization_details: null,
            },
            output: {
              format: 'pcm',
              provider: 'exotel',
            },
            synthesizer: {
              audio_format: 'mp3',
              provider: 'elevenlabs',
              stream: true,
              provider_config: {
                voice: 'Monika',
                model: 'eleven_turbo_v2_5',
                voice_id: '1qEiC6qsybMkmnNdVMbK',
              },
              buffer_size: 100,
              caching: true,
            },
            transcriber: {
              encoding: 'linear16',
              language: 'hi',
              model: 'deepgram',
              stream: true,
              sampling_rate: 8000,
              endpointing: 100,
              keywords: null,
              task: 'transcribe',
            },
          },
          task_config: {
            optimize_latency: true,
            hangup_after_silence: 10,
            incremental_delay: 100,
            number_of_words_for_interruption: 3,
            interruption_backoff_period: 100,
            hangup_after_LLMCall: false,
            call_cancellation_prompt: null,
            backchanneling: false,
            backchanneling_message_gap: 5,
            backchanneling_start_delay: 5,
            ambient_noise: false,
            max_duration: 120,
          },
        },
      ],
    },
    agentPrompts: {
      task_1: {
        system_prompt: '',
      },
    },
    loading: false,
    error: null,
  },
  reducers: {
    updateAgentConfig: (state, action) => {
      state.agentConfig = { ...state.agentConfig, ...action.payload.agentConfig };
      state.agentPrompts = { ...state.agentPrompts, ...action.payload.agentPrompts };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitAgentConfig.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitAgentConfig.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(submitAgentConfig.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { updateAgentConfig } = agentSlice.actions;
export default agentSlice.reducer;
