import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  MenuItem,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  FormControl,
  InputLabel,
  Alert,
  Pagination,
  IconButton,
} from '@mui/material';
import { fetchClients } from '../actions/clientActions';
import { updateBalance } from '../actions/balanceActions';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Wallet = () => {
  const [selectedClient, setSelectedClient] = useState('');
  const [availableBalance, setAvailableBalance] = useState(0);
  const [blockedBalance, setBlockedBalance] = useState(0);
  const [open, setOpen] = useState(false);
  const [balanceUpdate, setBalanceUpdate] = useState({ amount: 0, type: '', balanceType: '', description: '' });
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });

  const [balanceHistory, setBalanceHistory] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;

  const dispatch = useDispatch();
  const clients = useSelector((state) => state.client?.clients ?? []);

  useEffect(() => {
    dispatch(fetchClients());
  }, [dispatch]);

  useEffect(() => {
    if (selectedClient) {
      const client = clients.find(client => client._id === selectedClient);
      setAvailableBalance(client?.availableBalance || 0);
      setBlockedBalance(client?.blockedBalance || 0);
      fetchBalanceHistory();
    }
  }, [selectedClient, clients]);

  // Fetch balance history function
  const fetchBalanceHistory = () => {
    axios.post(`https://app.glimpass.com/exotel/get-billing-history-by-clientId`, {
      clientId: selectedClient,
    })
      .then(response => {
        setBalanceHistory(response.data); // Set the response data to balanceHistory state
      })
      .catch(error => {
        console.error('Error fetching balance history:', error);
      });
  };

  const handleClientChange = (event) => {
    setSelectedClient(event.target.value);
  };

  const handleOpen = (type, balanceType) => {
    let description = '';
    if (type === 'add' && balanceType === 'blocked') {
      description = 'SuperAdmin:  Blocked Balance (Debit)';
    } else if (type === 'add' && balanceType === 'available') {
      description = 'SuperAdmin: Recharge Available Balance (Credit)';
    } else if (type === 'release') {
      description = 'SuperAdmin: Releasing Blocked Balance (Credit)';
    } else if (type === 'vanish' && balanceType === 'available') {
      description = 'SuperAdmin: Vanished Available Balance (Debit)';
    } else if (type === 'vanish' && balanceType === 'blocked') {
      description = 'SuperAdmin: Vanished Blocked Balance (Credit)';
    }
    setBalanceUpdate({ amount: 0, type, balanceType, description });
    setOpen(true);
  };
  

  const handleClose = () => setOpen(false);
  const handleConfirmClose = () => setConfirmOpen(false);
  const handleAlertClose = () => setAlert({ open: false, message: '', severity: 'success' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBalanceUpdate((prevUpdate) => ({
      ...prevUpdate,
      [name]: value,
    }));
  };

  const handleConfirm = () => {
    const { amount, type, balanceType } = balanceUpdate;
    let newAvailableBalance = availableBalance;
    let newBlockedBalance = blockedBalance;

    if (balanceType === 'available' && type === 'add') {
      newAvailableBalance += Number(amount);
    } else if (balanceType === 'blocked') {
      if (type === 'add') {
        if (availableBalance < Number(amount)) {
          setAlert({
            open: true,
            message: 'Insufficient available balance to block this amount.',
            severity: 'error',
          });
          setOpen(false);
          return;
        }
        newAvailableBalance -= Number(amount);
        newBlockedBalance += Number(amount);
      } else if (type === 'release') {
        if (blockedBalance < Number(amount)) {
          setAlert({
            open: true,
            message: 'Insufficient blocked balance to release this amount.',
            severity: 'error',
          });
          setOpen(false);
          return;
        }
        newAvailableBalance += Number(amount);
        newBlockedBalance -= Number(amount);
      }
    }

    setAlert({
      open: true,
      message: `Available Balance: ${availableBalance} -> ${newAvailableBalance}`,
      severity: 'info',
    });

    setConfirmOpen(true);
    handleClose();
  };

  const handleFinalConfirm = async () => {
    const { amount, type, balanceType, description } = balanceUpdate;
    let newAvailableBalance = availableBalance;
    let newBlockedBalance = blockedBalance;

    try {
      // Adjust available and blocked balances based on the operation
      if (balanceType === 'available' && type === 'add') {
        newAvailableBalance += Number(amount); // Add to available balance
      } else if (balanceType === 'blocked') {
        if (type === 'add') {
          newAvailableBalance -= Number(amount); // Deduct from available balance
          newBlockedBalance += Number(amount); // Add to blocked balance
        } else if (type === 'release') {
          newAvailableBalance += Number(amount); // Add to available balance
          newBlockedBalance -= Number(amount); // Deduct from blocked balance
        }
      }

      // Ensure billing is recorded first
      await axios.post(`https://app.glimpass.com/exotel/add-sa-billing`, {
        clientId: selectedClient,
        balance: Number(amount),
        transactionType: type === 'add' && balanceType === 'blocked' ? 'Dr' : 'Cr',
        desc: description,
        newAvailableBalance: newAvailableBalance, // This reflects the balance after the transaction
        date: new Date(),
        camp_name: null,
        campaignId: null,
      });

      const client = clients.find(client => client._id === selectedClient);

      // Update the client data with the new balance
      dispatch(updateBalance({
        clientId: selectedClient,
        newDocs: {
          ...client, // Retain previous client data
          availableBalance: newAvailableBalance, // Updated available balance
          blockedBalance: newBlockedBalance, // Updated blocked balance
        }
      }));

      // Update the local state for balances
      setAvailableBalance(newAvailableBalance);
      setBlockedBalance(newBlockedBalance);

      setConfirmOpen(false);
      setAlert({ open: true, message: 'Balance updated successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error updating balance:', error);
      setAlert({ open: true, message: 'Error updating balance. Please try again.', severity: 'error' });
    }
  };

  // Function to format date to dd/mm/yy hr:min
  const formatDateTime = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Filter history based on search query
  const filteredHistory = balanceHistory.filter((record) =>
    record._id.includes(searchQuery) || record.desc.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const paginatedHistory = filteredHistory.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Container maxWidth="lg">
        <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
          <Typography variant="h4" gutterBottom>
            Wallet Management
          </Typography>
          <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
            <InputLabel>Email</InputLabel>
            <Select
              value={selectedClient}
              onChange={handleClientChange}
              label="Email"
            >
              {clients.map((client) => (
                <MenuItem key={client._id} value={client._id}>
                  {client.email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box display="flex" justifyContent="space-between" mb={4}>
          <Box flex={1} mr={2} p={2} border="1px solid grey" borderRadius="4px">
            <Typography variant="h6" gutterBottom>
              Available Balance
            </Typography>
            <Typography variant="body1" gutterBottom>
              {availableBalance}
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Button variant="contained" color="primary" onClick={() => handleOpen('add', 'available')}>
                Add Balance
              </Button>
              <Button disabled variant="contained" color="secondary" onClick={() => handleOpen('vanish', 'available')}>
                Vanish Balance
              </Button>
            </Box>
          </Box>

          <Box flex={1} ml={2} p={2} border="1px solid grey" borderRadius="4px">
            <Typography variant="h6" gutterBottom>
              Blocked Balance
            </Typography>
            <Typography variant="body1" gutterBottom>
              {blockedBalance}
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Button variant="contained" color="primary" onClick={() => handleOpen('add', 'blocked')}>
                Add Blocked Balance
              </Button>
              <Button variant="contained" color="secondary" onClick={() => handleOpen('release', 'blocked')}>
                Release Blocked Balance
              </Button>
              <Button disabled variant="contained" color="error" onClick={() => handleOpen('vanish', 'blocked')}>
                Vanish Blocked Balance
              </Button>
            </Box>
          </Box>
        </Box>

        {/* Balance Update Modal */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-title" variant="h6" component="h2">
              {balanceUpdate.type === 'add' ? 'Add Balance' : balanceUpdate.type === 'release' ? 'Release Balance' : 'Vanish Balance'}
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="amount"
              label="Amount"
              name="amount"
              type="number"
              value={balanceUpdate.amount}
              onChange={handleChange}
            />
            <Button onClick={handleConfirm} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Confirm
            </Button>
          </Box>
        </Modal>

        {/* Final Confirmation Modal */}
        <Modal
          open={confirmOpen}
          onClose={handleConfirmClose}
          aria-labelledby="modal-title-confirm"
          aria-describedby="modal-description-confirm"
        >
          <Box sx={style}>
            <Typography id="modal-title-confirm" variant="h6" component="h2">
              Confirm Update
            </Typography>
            <Typography id="modal-description-confirm" sx={{ mt: 2 }}>
              {balanceUpdate.type === 'add' && balanceUpdate.balanceType === 'available' ? (
                `Available Balance: ${availableBalance} -> ${availableBalance + Number(balanceUpdate.amount)}`
              ) : balanceUpdate.type === 'add' && balanceUpdate.balanceType === 'blocked' ? (
                `Blocked Balance: ${blockedBalance} -> ${blockedBalance + Number(balanceUpdate.amount)}, Available Balance: ${availableBalance} -> ${availableBalance - Number(balanceUpdate.amount)}`
              ) : balanceUpdate.type === 'release' ? (
                `Blocked Balance: ${blockedBalance} -> ${blockedBalance - Number(balanceUpdate.amount)}, Available Balance: ${availableBalance} -> ${availableBalance + Number(balanceUpdate.amount)}`
              ) : balanceUpdate.balanceType === 'blocked' ? (
                `Blocked Balance: ${blockedBalance} -> ${blockedBalance - Number(balanceUpdate.amount)}`
              ) : (
                `Available Balance: ${availableBalance} -> ${availableBalance - Number(balanceUpdate.amount)}`
              )}
            </Typography>
            <Button onClick={handleFinalConfirm} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Confirm Update
            </Button>
          </Box>
        </Modal>

        <Typography variant="h6" gutterBottom>
          Balance History
          <IconButton onClick={fetchBalanceHistory} sx={{ ml: 2 }}>
            <RefreshIcon />
          </IconButton>
        </Typography>

        {/* Search bar for balance history */}
        <TextField
          label="Search by _id or Description"
          variant="outlined"
          fullWidth
          sx={{ mb: 3 }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Transaction Type</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>New Balance</TableCell>
                <TableCell>_id</TableCell>
                <TableCell>campaignId</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedHistory.map((record, index) => (
                <TableRow key={index}>
                  <TableCell>{formatDateTime(record.date)}</TableCell>
                  <TableCell style={{ color: record.transactionType === 'Cr' ? 'green' : 'red' }}>
                    {record.transactionType}
                  </TableCell>
                  <TableCell>{record.balanceCount}</TableCell>
                  <TableCell>{record.desc}</TableCell>
                  <TableCell>{record.newAvailableBalance}</TableCell>
                  <TableCell>{record._id}</TableCell>
                  <TableCell>{record.campaignId || 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <Pagination
          count={Math.ceil(filteredHistory.length / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
          sx={{ mt: 3 }}
        />

        {alert.open && (
          <Alert severity={alert.severity} onClose={handleAlertClose} sx={{ mt: 2 }}>
            {alert.message}
          </Alert>
        )}
      </Container>
    </Box>
  );
};

export default Wallet;
