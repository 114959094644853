// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from './pages/Login';
import HomePage from './pages/HomePage';
import Assistants from './pages/Assistants';
import Wallet from './pages/Wallet';
import Sidebar from './components/Sidebar';
import Onboarding from './pages/Onboarding';
import ProtectedRoute from './components/ProtectedRoute';
import PublicRoute from './components/PublicRoute';
import { Box, CssBaseline } from '@mui/material';
import AssignAssistants from './pages/AssignAssistants';
import UpdateAssistant from './pages/UpdateAssistant';
import ListAssistants from './pages/ListAssistants';

const App = () => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  return (
    <Router>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {isAuthenticated && <Sidebar />}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            overflow: 'auto', // Ensure the main content can scroll if needed
            height: '100vh', // Ensure it takes the full viewport height
          }}
        >
          <Routes>
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <HomePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/assistants"
              element={
                <ProtectedRoute>
                  <Assistants />
                </ProtectedRoute>
              }
            />
            <Route
              path="/assign-assistants"
              element={
                <ProtectedRoute>
                  <AssignAssistants />
                </ProtectedRoute>
              }
            />
            <Route
              path="/update-assistant"
              element={
                <ProtectedRoute>
                  <UpdateAssistant />
                </ProtectedRoute>
              }
            />
            <Route
              path="/list-assistants"
              element={
                <ProtectedRoute>
                  <ListAssistants />
                </ProtectedRoute>
              }
            />
            <Route
              path="/add-money"
              element={
                <ProtectedRoute>
                  <Wallet />
                </ProtectedRoute>
              }
            />
            <Route
              path="/onboarding"
              element={
                <ProtectedRoute>
                  <Onboarding />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Box>
      </Box>
    </Router>
  );
};

export default App;
