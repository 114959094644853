import { createStore, applyMiddleware, compose } from 'redux';
import {thunk} from 'redux-thunk';
import rootReducer from '../reducers';

const initialState = {
  user: {
    isAuthenticated: !!sessionStorage.getItem('isAuthenticated'),
    name: sessionStorage.getItem('userName') || '',
    email: sessionStorage.getItem('userEmail') || '',
  },
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
