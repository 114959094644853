// src/reducers/clientReducer.js
const initialState = {
    clients: [],
    loading: false,
    error: null,
  };
  
  const clientReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_CLIENTS_SUCCESS':
        return {
          ...state,
          clients: action.payload,
        };
      case 'FETCH_CLIENTS_FAILURE':
        return {
          ...state,
          error: action.payload,
        };
      case 'CREATE_CLIENT_SUCCESS':
        return {
          ...state,
          clients: [...state.clients, action.payload],
        };
      case 'CREATE_CLIENT_FAILURE':
        return {
          ...state,
          error: action.payload,
        };
      case 'UPDATE_CLIENT_SUCCESS':
        return {
          ...state,
          clients: state.clients.map((client) =>
            client._id === action.payload._id ? action.payload : client
          ),
        };
      case 'UPDATE_CLIENT_FAILURE':
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default clientReducer;
  